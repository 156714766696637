import { FC, useEffect, useState, useRef } from 'react';
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
} from '@mui/material';
import { InputDefault } from '../../../../shared/components/inputs/InputDefault';
import AddIcon from '@mui/icons-material/Add';

interface ShareCouponInputProps {
  labelName: string;
  onChange: (emails: string[]) => void;
  placeholder?: string;
  onValidField?: (isValid: boolean) => void;
}

export const ShareCouponInput: FC<ShareCouponInputProps> = ({
  labelName,
  onChange,
  placeholder,
  onValidField,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [emails, setEmails] = useState<string[]>([]);
  const [openTooltip, setOpenTooltip] = useState(false);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  // const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   setInputValue(e.target.value);

  //   if (e.target.value.length === 0) {
  //     if (onValidField) {
  //       setOpenTooltip(false);
  //       onValidField(true);
  //       return;
  //     }
  //   }

  //   if (onValidField) {
  //     onValidField(false);
  //   }

  //   setTimeout(() => {
  //     setOpenTooltip(true);
  //   }, 4000);
  // };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setInputValue(value);
    setOpenTooltip(false);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    if (value.trim().length === 0) {
      if (onValidField) {
        onValidField(true);
      }
      setOpenTooltip(false);
      return;
    }

    if (onValidField) {
      onValidField(false);
    }

    debounceTimeout.current = setTimeout(() => {
      setOpenTooltip(true);
    }, 3000);
  };

  const handleAddEmail = (): void => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(inputValue);
    const isRepeated = emails.includes(inputValue);

    if (isValidEmail && !isRepeated) {
      setEmails([...emails, inputValue]);
      setInputValue('');
      setOpenTooltip(false);
      if (onValidField) {
        onValidField(true);
      }
    }
  };

  const handleDelete = (email: string): void => {
    setEmails(emails.filter((item) => item !== email));
  };

  const ActiveEmails = (): JSX.Element => (
    <Box>
      {emails.map((item, index) => (
        <Chip
          key={index}
          label={item}
          variant="filled"
          onDelete={() => handleDelete(item)}
        />
      ))}
    </Box>
  );

  useEffect(() => {
    onChange(emails);
  }, [emails, onChange]);

  useEffect(() => {
    if (openTooltip) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        setOpenTooltip(false);
      }, 5000);
    }
  }, [openTooltip]);

  return (
    <Stack direction="column" spacing={2}>
      <InputDefault
        variant="outlined"
        labelName={labelName}
        value={inputValue}
        onChange={handleEmailChange}
        fullWidth
        placeholder={placeholder}
        endIcon={
          <InputAdornment position="end">
            <IconButton onClick={handleAddEmail} edge="end">
              <Tooltip open={openTooltip} title="Agregar email" arrow>
                <AddIcon />
              </Tooltip>
            </IconButton>
          </InputAdornment>
        }
      />
      <ActiveEmails />
    </Stack>
  );
};
