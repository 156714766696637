import { StatusReport } from './reports-types';

export const parseReportStatus = (status: StatusReport): string => {
  switch (status) {
    case StatusReport.PENDING:
      return 'Pendiente';
    case StatusReport.SUCCESS:
      return 'Completado';
    case StatusReport.IN_PROGRESS:
      return 'En progreso';
    case StatusReport.ERROR:
      return 'Error';
    default:
      return 'Pendiente';
  }
};
