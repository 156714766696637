import React, { ReactElement } from 'react';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { useAuth } from '../../auth/auth-hook';
import { CustomHeader } from '../../../shared/components/layout/CustomHeader';
import { useReports } from '../reports-hooks';
import AutorenewIcon from '@mui/icons-material/Autorenew';

type ReportsLayoutProps = {
  children: ReactElement;
};

export const ReportsLayout: React.FC<ReportsLayoutProps> = ({ children }) => {
  const { user } = useAuth();
  const { refetch } = useReports();

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          background: 'white',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}>
        <Box
          paddingX={1}
          display={{ xs: 'flex' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={{ xs: 2 }}>
          <Typography
            sx={{ textAlign: { xs: 'center', md: 'start' } }}
            fontSize={{ xs: '18px', md: '22px' }}
            marginBottom={{ xs: 1, md: 0 }}
            fontWeight={700}>
            Reportes
          </Typography>
          <Box>
            <IconButton onClick={() => refetch()}>
              <AutorenewIcon />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Container>
    </>
  );
};
