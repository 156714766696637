import { createAction } from '@cobuildlab/react-simple-state';
import { fetchReportsEvent, fetchReportsEventError } from './reports-events';
import { Tenant } from '../../shared/types/generated';
import axios from 'axios';

export const fetchTenantReports = createAction(
  fetchReportsEvent,
  fetchReportsEventError,
  async (data: {
    tenant: Tenant | null | undefined;
    from: string;
    to: string;
    page: number;
    pageSize: number;
  }) => {
    const { tenant, page, pageSize } = data;
    const url = `${tenant?.webhook}/api/tenant-reports`;

    try {
      const response = await axios.get(url, {
        timeout: 60000,
        params: {
          page,
          pageSize,
        },
      });

      return response.data;
    } catch (error: any) {
      const msg =
        error?.response?.data?.message ?? 'Error al obtener los cupones';
      throw new Error(msg);
    }
  },
);
