import React, { useEffect } from 'react';
import { Box, Grid, Hidden, Stack, Typography } from '@mui/material';
import { DashboardGeneralDateText } from '../DashboardGeneralDateText';
import { DateRangePicker } from '../../../../shared/components/DateRangePicker';
import {
  sanitizeCustomDate,
} from '../../../../shared/utils';
import { DateFilter } from '../../../../shared/constants';
import { changeDashboardDateFilterStoreAction } from '../../dashboard-actions';
import { useAuth } from '../../../auth/auth-hook';
import { DashboardTotalSubscriptionItem } from './DashboardTotalSubscriptionItem';
import {
  fetchGenerateTotalSubscriptionUserReport,
  fetchSubscriptionByMonth,
  fetchSubscriptionsCountStoreAction,
} from '../../../subscription/subscription-actions';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { DashboardTotalSubscriptionByFilterItem } from './DashboardTotalSubscriptionByFilterItem';
import { DashboardSubscriptionByMonthBar } from './DashboardSubscriptionByMonthBar';
import { DashboardSubscriptionByPaymentMethodBar } from './DashboardSubscriptionByPaymentMethodBar';
import { DashboardSubscriptionsTable } from './DashboardSubscriptionsTable';
import { DashboardTotalSubscriptionActiveItem } from './DashboardTotalSubscriptionActiveItem';
import { DashboardTotalActiveSubscriptionWithoutFilterItem } from './DashboardTotalActiveSubscriptionWithoutFilterItem';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import * as XLSX from 'xlsx';
import moment from 'moment/moment';
import { getDateFilterText, subscriptionStatus } from '../../dashboard-utils';
import { saveAs } from 'file-saver';
import { DashboardSubscriptionByPrice } from './DashboardSubscriptionByPrice';
import { DashboardWinnerSubscriptionNoteContainer } from './DashboardWinnerSubscriptionNoteContainer';
import { formatCurrencyMXN } from '../../../../shared/utils';
import { DashboardSubscriptionsNextExpiredTable } from './DashboardSubscriptionsNextExpiredTable';
import { openSnackbarAction } from '../../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../../shared/components/snackbar/snackbar-types';

export const DashboardSubscriptionTab: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant, user } = useAuth();

  const [callAction] = useCallAction(fetchSubscriptionByMonth, {
    onCompleted: (data) => {
      fetchSubscriptionsCountStoreAction(false, data);
    },
    onError: () => {
      fetchSubscriptionsCountStoreAction(false, []);
    },
  });

  const [callReport, loadingReport] = useCallAction(
    fetchGenerateTotalSubscriptionUserReport,
    {
      onCompleted: ({ report }) => {
        if (!report || report?.length === 0 || typeof report === 'string') {
          openSnackbarAction(
            'Su reporte estara disponible en unos minutos',
            TypeMessage.SUCCESS,
          );
          return;
        }
        const worksheet = XLSX.utils.json_to_sheet(
          report.map((r) => {
            //console.log({r});
            return {
              ID: r.id,
              'Fecha inicio periodo': moment(
                r.period_start_date || r.date,
              ).format('YYYY-MM-DD HH:mm'),
              'Fecha fin periodo': moment(r.period_end_date).format(
                'YYYY-MM-DD HH:mm',
              ),
              nombre: r.user ? r.user.name || '' : '',
              correo: r.user ? r.user.email || '' : '',
              plan: r.plan.title,
              'Frecuencia de Plan':
                r.plan.frequency === 'YEAR' ? 'Anual' : 'Mensual',
              monto: formatCurrencyMXN(r.plan.price),
              status: subscriptionStatus(r.status),
              subscription_id: r.transaction_id || '',
              user_id: r.user ? r.user.id || '' : '',
              'Fecha Cancelación': r?.cancel_date
                ? moment(r.cancel_date).format('YYYY-MM-DD HH:mm')
                : '',
              'Metodo de pago':
                r.payment_method === 'STRIPE_CARD'
                  ? 'STRIPE'
                  : r.payment_method,
              'Tipo de Pago':
                r.type === 'PAYMENT' ? 'Primer pago' : 'Pago recurrente',
              'Url de suscripción': r.subscription_url
                ? r.subscription_url
                : 'https://miperfil.animalpolitico.com/planes',
              ...(r.user?.subscription_billing?.address && {
                'Calle y número': r.user.subscription_billing.address,
              }),
              ...(r.user?.subscription_billing?.address1 && {
                Colonia: r.user.subscription_billing.address1,
              }),
              ...(r.user?.subscription_billing?.city && {
                Ciudad: r.user.subscription_billing.city,
              }),
              ...(r.user?.subscription_billing?.state && {
                Estado: r.user.subscription_billing.state,
              }),
              ...(r.user?.subscription_billing?.country && {
                Pais: r.user.subscription_billing.country,
              }),
              ...(r.user?.subscription_billing?.postal_code && {
                'Código postal': r.user.subscription_billing.postal_code,
              }),
              ...(r.user?.subscription_billing?.phone && {
                Teléfono: r.user.subscription_billing.phone,
              }),
              ...(r.user?.subscription_billing?.phone1 && {
                'Teléfono 2': r.user.subscription_billing.phone1,
              }),
            };
          }),
        );

        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `${getDateFilterText(from, to, filter)}`,
        );

        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });

        const blob = new Blob([excelBuffer], {
          type: 'application/octet-stream',
        });

        saveAs(blob, 'Reporte total.xlsx');
      },
    },
  );

  useEffect(() => {
    if (tenant) {
      callAction({
        tenant,
        from,
        to,
        filter: filter,
      });
    }
  }, [tenant, callAction, from, to, filter]);
  return (
    <Grid container justifyContent="center">
      <Grid xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems="center">
              <Typography>Informe general de registro de usuarios</Typography>
              <Hidden smUp>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  width="100%"
                  py="5px">
                  <ButtonDefault
                    onClick={() =>
                      callReport({
                        user,
                        tenant,
                        from: '',
                        to: '',
                      })
                    }
                    startIcon={
                      !loadingReport ? (
                        <img
                          src="/icon-clip.svg"
                          alt="clip"
                          width={14}
                          height={14}
                        />
                      ) : null
                    }
                    isLoading={loadingReport}
                    style={{
                      width: 195,
                      height: 40,
                      textTransform: 'inherit',
                      fontWeight: 700,
                      background: tenant?.identityColor ?? 'black',
                    }}>
                    Descargar reporte
                  </ButtonDefault>
                </Box>
              </Hidden>
              <Box
                display="flex"
                alignItems="center"
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                    justifyContent: 'flex-end',
                  },
                })}>
                <Hidden smDown>
                  <ButtonDefault
                    onClick={() =>
                      callReport({
                        user,
                        tenant,
                        from: '',
                        to: '',
                      })
                    }
                    sx={{ marginRight: '5px' }}
                    startIcon={
                      !loadingReport ? (
                        <img
                          src="/icon-clip.svg"
                          alt="clip"
                          width={14}
                          height={14}
                        />
                      ) : null
                    }
                    isLoading={loadingReport}
                    style={{
                      width: 195,
                      height: 40,
                      textTransform: 'inherit',
                      fontWeight: 700,
                      background: tenant?.identityColor ?? 'black',
                    }}>
                    Descargar reporte
                  </ButtonDefault>
                </Hidden>
                <DashboardGeneralDateText />
                <DateRangePicker
                  onChange={(range) => {
                    const dateFilter = sanitizeCustomDate(
                      range.key as DateFilter,
                      {
                        startDate: range.startDate,
                        endDate: range.endDate,
                      },
                    );

                    changeDashboardDateFilterStoreAction(
                      moment(range.startDate).startOf('day').utc(true).format(),
                      moment(range.endDate).endOf('day').utc(true).format(),
                      dateFilter,
                    );
                    fetchSubscriptionsCountStoreAction(true, []);
                  }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardTotalSubscriptionItem />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardTotalActiveSubscriptionWithoutFilterItem />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardTotalSubscriptionByFilterItem />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardTotalSubscriptionActiveItem />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardSubscriptionByMonthBar />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardSubscriptionByPaymentMethodBar />
          </Grid>
          <Grid item xs={12}>
            <DashboardWinnerSubscriptionNoteContainer />
          </Grid>
          <Grid item xs={12}>
            <DashboardSubscriptionByPrice />
          </Grid>
          <Grid item xs={12}>
            <DashboardSubscriptionsTable />
          </Grid>
          <Grid item xs={12}>
            <DashboardSubscriptionsNextExpiredTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
