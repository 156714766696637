import { useEvent } from '@cobuildlab/react-simple-state';
import { refetchReportsEvent } from './reports-events';

interface UseReportsReturn {
  refetch: () => void;
}

export const useReports = (): UseReportsReturn => {
  const value = useEvent(refetchReportsEvent);

  const refetchReports = (): void => {
    refetchReportsEvent.dispatch(!value);
  };

  return {
    refetch: refetchReports,
  };
};
