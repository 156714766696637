import { styled, Typography } from '@mui/material';
import { StatusReport } from '../../../modules/reports/reports-types';

export const ColumnTitle = styled(Typography)(() => ({
  fontWeight: 500,
  color: 'rgba(187, 187, 187, 1)',
}));

export const ColumnStatus = styled(Typography)<{ active: boolean }>(
  ({ active = false }) => ({
    fontWeight: 500,
    color: active ? 'rgba(0, 202, 84, 1)' : 'rgba(232, 16, 16, 1)',
  }),
);

export const ColumnReportStatus = styled(Typography)<{ status: StatusReport }>(
  ({ status = StatusReport.PENDING }) => ({
    fontWeight: 500,
    color: 'rgb(255,152,0)',
    ...(status === StatusReport.ERROR && { color: 'rgba(232, 16, 16, 1)' }),
    ...(status === StatusReport.SUCCESS && { color: 'rgba(0, 202, 84, 1)' }),
  }),
);
