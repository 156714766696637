import {
  useCallAction,
  useEvent,
  useStore,
  useSubscription,
} from '@cobuildlab/react-simple-state';
import { FC, useCallback, useEffect, useState } from 'react';
import { fetchTenantReports } from './reports-actions';
import { TableColumnType } from '../../shared/components/table/table-types';
import { ReportType } from './reports-types';
import moment from 'moment';
import { CardSection } from '../../shared/components/card/CardSection';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import { changeDashboardDateFilterStore } from '../dashboard/dashboard-events';
import { useAuth } from '../auth/auth-hook';
import { fetchReportsEvent, refetchReportsEvent } from './reports-events';
import { TableDefaultSticky } from '../../shared/components/table/TableDefaultSticky';
import {
  ColumnReportStatus,
  ColumnTitle,
} from '../../shared/components/text/Typographys';
import { Box, Link } from '@mui/material';
import { parseReportStatus } from './reports-utls';
import { parseToUrl } from '../../shared/utils/utils';
import { TooltipReportStatus } from './components/TooltipReportStatus';

export const ReportsView: FC = () => {
  const { tenant } = useAuth();
  const { items, total } = useEvent(fetchReportsEvent);
  const { from, to } = useStore(changeDashboardDateFilterStore);

  const [fetchReports, loading] = useCallAction(fetchTenantReports, {
    onError(error) {
      console.error('Error fetching reports', error);
      openSnackbarAction('Error al cargar los reportes.', TypeMessage.ERROR);
    },
  });

  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const refetch = useCallback((): void => {
    fetchReports({
      tenant,
      from,
      to,
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
  }, [fetchReports, from, pagination, tenant, to]);

  useSubscription(refetchReportsEvent, () => {
    refetch();
  });

  useEffect(() => {
    fetchReports({
      tenant,
      from,
      to,
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
  }, [fetchReports, from, to, pagination, tenant]);

  const COLUMNS: TableColumnType<ReportType>[] = [
    {
      columnName: <ColumnTitle textAlign='left'>Nombre</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <Box>
          {item.name}
        </Box>
      ),
    },
    {
      columnName: <ColumnTitle>Archivos</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <Box>
          {
            item.files.map((file) => (
              <Link
                onClick={() =>
                  window.open(parseToUrl(file.path), '_blank')
                }
                display={'flex'}
                align='left'
                sx={{ cursor: 'pointer' }}>
                {file.name}
              </Link>
            ))
          }
        </Box>
      ),
    },
    {
      columnName: <ColumnTitle>Archivos procesados</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <Box>
          {item.files.length}/{item.count_files}
        </Box>
      ),
    },
    {
      columnName: <ColumnTitle>Solicitante</ColumnTitle>,
      columnValue: (item) => item.requested_by || '',
    },
    {
      columnName: <ColumnTitle>Status</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <TooltipReportStatus
          title={
            item.status === 'ERROR'
              ? `${parseReportStatus(item.status)}: ${item.status_message}`
              : parseReportStatus(item.status)
          }
          placement='top'>
          <ColumnReportStatus status={item.status}>
            <>{parseReportStatus(item.status)}</>
          </ColumnReportStatus>
        </TooltipReportStatus>
      ),
    },
    {
      columnName: <ColumnTitle>Fecha de creacion</ColumnTitle>,
      columnValue: (item) =>
        moment(item.created_at).format('DD/MM/YYYY hh:mm A'),
    },
  ];

  return (
    <CardSection showCardHeader={false}>
      <TableDefaultSticky
        items={items || []}
        count={total}
        columns={COLUMNS}
        loading={loading}
        messageLoading={'Cargando reportes...'}
        messageEmpty={'No hay reportes disponibles'}
        page={pagination.page}
        pageSize={pagination.pageSize}
        height={'auto'}
        handleChangePage={(page) => {
          if (tenant) {
            setPagination({
              ...pagination,
              page: page + 1,
            });
          }
        }}
        rowsPerPageOptions={[10, 20, 50, 100]}
        handleChangeRowPerPage={(e) => {
          setPagination({
            ...pagination,
            pageSize: parseInt(e.target.value, 10),
            page: 1,
          });
        }}
      />
    </CardSection>
  );
};
