import { createEvent } from '@cobuildlab/react-simple-state';
import { ReportType } from './reports-types';

export const fetchReportsEvent = createEvent<{
  items: ReportType[];
  total: number;
}>({
  initialValue: {
    items: [],
    total: 0,
  },
});

export const fetchReportsEventError = createEvent<Error>();

export const refetchReportsEvent = createEvent<boolean>({
  initialValue: false,
});
