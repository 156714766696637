export const formatFrequencyPlan = (frequency: 'YEAR' | 'MONTH'): string => {
  switch (frequency) {
    case 'YEAR':
      return 'Anual';
    case 'MONTH':
      return 'Mensual';
  }
};

export const formatStatus = (frequency: 'ACTIVE' | 'INACTIVE'): string => {
  switch (frequency) {
    case 'ACTIVE':
      return 'Activo';
    case 'INACTIVE':
      return 'Inactivo';
  }
};

export const parseToUrl = (dynamicUrl: string): string => {
  const baseUrl = 'https://';
  let finalUrl = dynamicUrl;

  if (!dynamicUrl.startsWith('http://') && !dynamicUrl.startsWith('https://')) {
    finalUrl = baseUrl + dynamicUrl;
  }

  try {
    const parsedUrl = new URL(finalUrl);
    return parsedUrl.toString();
  } catch (error) {
    console.error(`Error parsing URL: ${dynamicUrl}`, error);
    return finalUrl;
  }
};
